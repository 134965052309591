import React from "react";
import SEO from "../components/SEO";

export default () => {
  return (
    <div>
      <SEO title="404" description="Game Over" pathname="404" />
      <div className="slim-container md-page">
        <h1>What in the world are you doing here? This page is gone.</h1>
      </div>
    </div>
  );
};
